import React from 'react'
import './porfolio.css'
import img from '../../assets/shopping.png'
import img2 from '../../assets/ferm.png'
import img3 from '../../assets/img3.png'
import img4 from '../../assets/DIGICom.png'
import img7 from '../../assets/silico.png'
import img8 from "../../assets/dilam.png";
const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>Projets en cours</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        <div className="portfolio__articles">
          <article className="portfolio__article">
            <div className="portfolio__article-img">
              <img src={img} className="img__site" alt="site1" />
            </div>
            <h3>ElectroShopping</h3>
            <div className="portfolio_article-info">
              <a
                href="https://github.com/NovzChrono"
                className="btn"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
              <a
                href="https://electroshopdigi.000webhostapp.com/"
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                Demo
              </a>
            </div>
          </article>
          <article className="portfolio__article">
            <div className="portfolio__article-img">
              <img src={img7} className="img__site" alt="site4" />
            </div>
            <h3>SilicoDB</h3>
            <div className="portfolio_article-info">
              <a
                href="https://github.com/NovzChrono"
                className="btn"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
              <a
                href="#portfolio"
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                Demo
              </a>
            </div>
          </article>
          <article className="portfolio__article">
            <div className="portfolio__article-img">
              <img src={img4} className="img__site" alt="site4" />
            </div>
            <h3>DIGICom</h3>
            <div className="portfolio_article-info">
              <a
                href="https://github.com/NovzChrono"
                className="btn"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
              <a
                href="#portfolio"
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                Demo
              </a>
            </div>
          </article>
          <article className="portfolio__article">
            <div className="portfolio__article-img">
              <img src={img8} className="img__site" alt="site4" />
            </div>
            <h3>DIlam Consulting</h3>
            <div className="portfolio_article-info">
              <a
                href="https://github.com/NovzChrono"
                className="btn"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
              <a
                href="#portfolio"
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                Demo
              </a>
            </div>
          </article>
          
          <article className="portfolio__article">
            <div className="portfolio__article-img">
              <img src={img3} className="img__site" alt="site3" />
            </div>
            <h3>Calculatrice</h3>
            <div className="portfolio_article-info">
              <a
                href="https://github.com/NovzChrono"
                className="btn"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
              <a
                href="https://calculatrice-novz.netlify.app/"
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                Demo
              </a>
            </div>
          </article>
          <article className="portfolio__article">
            <div className="portfolio__article-img">
              <img src={img2} className="img__site" alt="site2" />
            </div>
            <h3>Femaci</h3>
            <div className="portfolio_article-info">
              <a
                href="https://github.com/NovzChrono"
                className="btn"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
              <a
                href="https://maisprod.000webhostapp.com/public"
                className="btn btn-primary"
                target="_blank"
                rel="noreferrer"
              >
                Demo
              </a>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
}

export default Portfolio