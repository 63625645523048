import React, {useState, useEffect, useRef} from 'react'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'
import './propos.css'
import img from '../../assets/img-3.png'
import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)


const Propos = () => {
  const [toggle, setToggle] = useState(false);
  const changeState = () => {
    setToggle(!toggle)
  }
  const Picture = useRef(null)
  useEffect(() => {
    toggle ?
    gsap.to(Picture.current,
          {
            scale:1.2,
            rotate:360,
            duration:0.9
          }
        )
        :
    gsap.to(Picture.current,
          {
            scale:1,
            rotate:0,
            duration:0.9
          }
        )
  }, [toggle]);


  const Left = (element, delay, duration) => {
        gsap.fromTo(
            element,{
                opacity : 0,
                x: -200
            },
            {
                opacity : 1,
                x: 0,
                delay: delay || "0.3",
                duration: duration || "0.5",
                scrollTrigger:{
                    trigger: element,
                    start: "top center",
                    end: "bottom center"
                }
            }
        )
  }
  useEffect(() => {
        Left("#propos")
    }, []);
    
  return (
    <section id='propos'>
      <h5>Me connaitre</h5>
      <h2>A propos</h2>
      <div className='container propos__container'>
        <div className='propos__me'>
          <img src={img} alt="img_me" className='propos__me-image' onClick={changeState} ref={Picture} />
        </div>
        <div className='propos__content'>
          <div className='propos__cards'>
            <article className='propos__card'>
              <FaAward className='propos_icon'/>
              <h5>Expérience</h5>
              <small>3 ans</small>
            </article>
            <article className='propos__card'>
              <FiUsers className='propos_icon'/>
              <h5>Clients</h5>
              <small>10+</small>
            </article>
            <article className='propos__card'>
              <VscFolderLibrary className='propos_icon'/>
              <h5>Projets</h5>
              <small>30+</small>
            </article>

            
          </div>
            <p>
              Passionné par l'univers du web et mobile et doté d'une grande curiosité,
              je suis aujourd'hui développeur web Full Stack.
            </p>

            <a href='#contact' className='btn btn-primary'>Contactez moi</a>
        </div>
      </div>
    </section>
  )
}

export default Propos