import React from 'react'

const Info = () => {
  return (
    <div className="Info">
      <a href="#contact" className="btn btn-primary">
        Me contacter
      </a>
    </div>
  );
}

export default Info