import React, {createContext, useState} from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import Propos from './components/propos/Propos'
import Experience from './components/experience/Experience'
import Portfolio from './components/portfolio/Portfolio'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import { MdDarkMode, MdLightMode } from "react-icons/md";
export const ThemeContext = createContext(null)

const App = () => {
  const [theme, setTheme] = useState("light")
  const [color, setColor] = useState()
  const ToggleTheme = () => {

    setTheme((curr) => curr === 'dark' ? 'light' : 'dark')

    setColor('rgb(1, 62, 112)')
  }

  
  return (
    <ThemeContext.Provider value={{ theme, ToggleTheme }}>
      <div className="theme" id={theme}>
        <div className="switch__content">
          <span
            onClick={ToggleTheme}
            backgroundColor={color}
            checked={theme === "dark"}
          >
            {theme === "light" ? (
              <MdDarkMode className="switch__element" size={30} />
            ) : (
              <MdLightMode className="switch__element" size={30} />
            )}
          </span>
        </div>
        <Header />
        <Nav />
        <Propos />
        <Experience />
        <Portfolio />
        <Contact />
        <Footer />
      </div>
    </ThemeContext.Provider>
  );
}


export default App