import React, {useEffect} from 'react'
import './experience.css'
import {DiPhp,DiPhotoshop,DiIllustrator, DiMongodb} from 'react-icons/di'
import {SiJavascript, SiNestjs} from 'react-icons/si'
import {GrMysql} from 'react-icons/gr'
import {SiTailwindcss} from 'react-icons/si'
import {FiSettings} from 'react-icons/fi'
import {RiFlutterFill} from 'react-icons/ri'
import { SiSpringboot } from "react-icons/si";
import {
  FaHtml5,
  FaCss3,
  FaBootstrap,
  FaReact,
  FaLaravel,
  FaSymfony,
  FaAngular,
} from "react-icons/fa";
import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const Experience = () => {

  const Left = (element, delay, duration) => {
        gsap.fromTo(
            element,{
                opacity : 0,
                x: -200
            },
            {
                opacity : 1,
                x: 0,
                delay: delay || "0.3",
                duration: duration || "0.4",
                scrollTrigger:{
                    trigger: element,
                    start: "top center",
                    end: "bottom center"
                }
            }
        )
  }
  useEffect(() => {
        Left("#experience")
    }, []);

  return (
    <section id="experience">
      <h5>Les compétences que j'ai</h5>
      <h2>Mes compétences</h2>
      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Technologies frontend utilisées</h3>
          <div className="experience__content">
            <article className="experience__article">
              <FaHtml5 className="experience_article-icon" />
              <div>
                <h4>HTML</h4>
                <small className="text-light">Expérimenté</small>
              </div>
            </article>
            <article className="experience__article">
              <FaCss3 className="experience_article-icon" />
              <div>
                <h4>CSS</h4>
                <small className="text-light">Bon</small>
              </div>
            </article>
            <article className="experience__article">
              <SiJavascript className="experience_article-icon" />
              <div>
                <h4>JavaScript</h4>
                <small all className="text-light">
                  Expérimenté
                </small>
              </div>
            </article>
            <article className="experience__article">
              <FaReact className="experience_article-icon" />
              <div>
                <h4>ReactJs</h4>
                <small className="text-light">Bon</small>
              </div>
            </article>

            <article className="experience__article">
              <FaAngular className="experience_article-icon" />
              <div>
                <h4>Angular 16</h4>
                <small className="text-light">Bon</small>
              </div>
            </article>
            <article className="experience__article">
              <FaBootstrap className="experience_article-icon" />
              <div>
                <h4>Bootstrap</h4>
                <small className="text-light">Expérimenté</small>
              </div>
            </article>
            <article className="experience__article">
              <SiTailwindcss className="experience_article-icon" />
              <div>
                <h4>Tailwindcss</h4>
                <small className="text-light">Expérimenté</small>
              </div>
            </article>
            <article className="experience__article">
              <RiFlutterFill className="experience_article-icon" />
              <div>
                <h4>Flutter</h4>
                <small className="text-light">Bon</small>
              </div>
            </article>
            <article className="experience__article">
              <FaReact className="experience_article-icon" />
              <div>
                <h4>React Native</h4>
                <small className="text-light">Bon</small>
              </div>
            </article>
            
          </div>
        </div>
        <div className="experience__backend">
          <h3>Technologies Backend utilisées</h3>
          <div className="experience__content">
            <article className="experience__article">
              <DiPhp className="experience_article-icon" />
              <div>
                <h4>PHP</h4>
                <small className="text-light">Expérimenté</small>
              </div>
            </article>
            <article className="experience__article">
              <FaLaravel className="experience_article-icon" />
              <div>
                <h4>Laravel</h4>
                <small className="text-light">Expérimenté</small>
              </div>
            </article>
            <article className="experience__article">
              <SiNestjs className="experience_article-icon" />
              <div>
                <h4>NestJs</h4>
                <small className="text-light">Expérimenté</small>
              </div>
            </article>
            <article className="experience__article">
              <FaSymfony className="experience_article-icon" />
              <div>
                <h4>Symfony</h4>
                <small className="text-light">Bon</small>
              </div>
            </article>
            <article className="experience__article">
              <SiSpringboot className="experience_article-icon" />
              <div>
                <h4>Spring Boot</h4>
                <small className="text-light">Bon</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__backend">
          <h3>Database</h3>
          <div className="experience__content">
            <article className="experience__article">
              <GrMysql className="experience_article-icon" />
              <div>
                <h4>MySql</h4>
                <small className="text-light">Expérimenté</small>
              </div>
            </article>
            <article className="experience__article">
              <DiMongodb className="experience_article-icon" />
              <div>
                <h4>Mongodb</h4>
                <small className="text-light">Bon</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__infographie">
          <h3>Infographie</h3>
          <div className="experience__content">
            <article className="experience__article">
              <DiPhotoshop className="experience_article-icon" />
              <div>
                <h4>Photoshop</h4>
                <small className="text-light">Expérimenté</small>
              </div>
            </article>
            <article className="experience__article">
              <DiIllustrator className="experience_article-icon" />
              <div>
                <h4>Illustrator</h4>
                <small className="text-light">Bon</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__autres">
          <h3>Autres</h3>
          <div className="experience__content">
            <article className="experience__article">
              <FiSettings className="experience_article-icon" />
              <div>
                <h4>Maintenance informatique</h4>
                <small className="text-light">Bon</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experience