import React, { useRef } from 'react'
import './contact.css'
import {RiMailSendLine, RiMessengerFill,RiWhatsappFill} from 'react-icons/ri'
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_h2h1e04",
        "template_kucl6j8",
        form.current,
        "z0vgIuYkWqRWLYE_p"
      )
      .then(
        (result) => {
          Swal.fire({
            icon: "success",
            title: "Votre message a été envoyé",
          });
          e.target.reset();
        },
        (error) => {
          Swal.fire({ icon: "error", title: error.text });
        }
      );


  };
  return (
    <section id="contact">
      <h5>Besoin de mes services?</h5>
      <h2>Contactez-moi</h2>
      <div className="container contact__container">
        <div className="contact__content">
          <div className="contact__options">
            <article className="contact__option">
              <RiMailSendLine className="contact__option-icon" />
              <h4>Email</h4>
              <h5>contact@traore-lamine.site</h5>
              <a href="mailto:contact@traore-lamine.site">Envoyer un message</a>
            </article>
            <article className="contact__option">
              <RiMessengerFill className="contact__option-icon" />
              <h4>Messenger</h4>
              <h5>Cod Eur</h5>
              <a href="https://m.me/lamine.traore.1998/" rel="noreferrer">
                Envoyer un messenge
              </a>
            </article>
            <article className="contact__option">
              <RiWhatsappFill className="contact__option-icon" />
              <h4>WhatsApp</h4>
              <h5>+225 07 0405 1152</h5>
              <a
                href="https://wa.me/+2250704051152?text=Coucou"
                rel="noreferrer"
              >
                Envoyer un message
              </a>
            </article>
          </div>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input
            name="nom"
            type="text"
            placeholder="Entrez votre nom"
            required
          />
          <input
            name="phone_number"
            type="text"
            placeholder="Entrez votre numéro de téléphone"
            required
          />
          <input
            name="email"
            type="email"
            placeholder="Entrez votre mail"
            required
          />
          <textarea
            name="message"
            placeholder="Votre message"
            rows="7"
            required
          ></textarea>
          <div className="contact__button">
            <button type="submit" className="btn btn-primary">
              Envoyer le message
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Contact