import React,{useState} from 'react'
import {AiOutlineHome,AiOutlineUser} from 'react-icons/ai'
import {BiBook, BiMessageSquareDetail,BiArrowToBottom} from 'react-icons/bi'
import {FaProjectDiagram} from 'react-icons/fa'
import './nav.css'

const Nav = () => {

  const [active, setActive] = useState('#')

  return (
    <nav>
      <a href="#header" className={active === '#' ? 'active' : ''} onClick={() => setActive('#')}><AiOutlineHome /></a>
      <a href="#propos" className={active === '#propos' ? 'active' : ''} onClick={() => setActive('#propos')}><AiOutlineUser /></a>
      <a href="#experience" className={active === '#experience' ? 'active' : ''} onClick={() => setActive('#experience')}><BiBook /></a>
      <a href="#portfolio" className={active === '#portfolio' ? 'active' : ''} onClick={() => setActive('#portfolio')}><FaProjectDiagram /></a>
      <a href="#contact" className={active === '#contact' ? 'active' : ''} onClick={() => setActive('#contact')}><BiMessageSquareDetail /></a>
      <a href="#footer" className={active === '#footer' ? 'active' : ''} onClick={() => setActive('#footer')}><BiArrowToBottom /></a>

      
    </nav>
  )
}

export default Nav