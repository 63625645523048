import React from 'react'
import {BsLinkedin,BsGithub,BsInstagram} from 'react-icons/bs'

const HeaderSocial = () => {
  return (
    <div className='header_socials'>
      <a href="https://www.linkedin.com/in/l-t-84bb7423a/" rel="noreferrer"><BsLinkedin /></a>
      <a href="https://github.com/NovzChrono" rel="noreferrer"><BsGithub /> </a>
      <a href="https://www.instagram.com/noirchron/?hl=fr" rel="noreferrer"><BsInstagram /> </a>
    </div>
  )
}

export default HeaderSocial